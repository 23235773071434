




































import {Component, Vue} from 'vue-property-decorator';
import ExpirableDocumentDTO from "@/dto/ExpirableDocumentDTO"
import DocumentService from "@/services/DocumentService";
import PaginationComponent from "@/components/util/PaginationComponent.vue";
import RouteNames from "@/router/RouteNames";
import {namespace} from "vuex-class";
import {WORKSPACE_ID, WORKSPACE_TYPE} from "@/constants/Paths";
import {Dictionary} from "vue-router/types/router";
import {WorkspaceTypePathElement} from "@/dto/auth/Workspace";
import {ExpirableDocumentType} from "@/constants/DocumentConstants";
import {processError} from "@/utils/ComponentUtils";

const AppModule = namespace("App");

@Component(
    {
      components: {PaginationComponent}
    }
)

export default class ExpiredDocumentsTable extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  private pageNumber = 1

  private totalPages = 1

  private changePage(page: number): void {
    this.pageNumber = page;
    this.getExpiringDocumentPage();
  }


  private expiredDocuments: ExpirableDocumentDTO[] = []

  mounted() {
    this.getExpiringDocumentPage();
  }


  getExpiringDocumentPage() {
    this.startLoading()
    DocumentService.getExpirableDocumentPage(this.pageNumber, 20).then(
        response => {

          this.expiredDocuments = response.data.content;
          this.totalPages = response.data.totalPages;
          this.stopLoading()
        },
        error => {
          this.stopLoading();
          processError(this, error);
        }
    )
  }

  getRowColor(document: ExpirableDocumentDTO) {
    const expirationDate = new Date(document.expirationDate);
    const today = new Date();
    const nextWeek = new Date();
    nextWeek.setDate(nextWeek.getDate() + 7);

    if (expirationDate < today) {
      return 'expired-row';
    } else if (expirationDate < nextWeek) {
      return 'expiring-soon-row';
    } else {
      return '';
    }
  }

  gotoRequest(document: ExpirableDocumentDTO) {
    const params: Dictionary<string> = {}
    if (document.person) {
      params[WORKSPACE_TYPE] = WorkspaceTypePathElement.PERSON
      params[WORKSPACE_ID] = `${document.person.id}`
      switch (document.type) {
        case ExpirableDocumentType.EAD:
          this.$router.push({name: RouteNames.PROFILE_PERSON_EAD, params: params});
          break;
        case ExpirableDocumentType.CITIZEN_PASSPORT:
          this.$router.push({name: RouteNames.PROFILE_PERSON_USPASSPORT, params: params});
          break;
        case ExpirableDocumentType.STATE_ID:
          this.$router.push({name: RouteNames.PROFILE_PERSON_ID, params: params});
          break;
        case ExpirableDocumentType.GREEN_CARD:
          this.$router.push({name: RouteNames.PROFILE_PERSON_GREENCARD, params: params});
          break;
      }
    }
  }
}
